import { createSlice } from '@reduxjs/toolkit';
import {ExerciseStatisticsType, ExerciseType, WorkoutType} from "../type/StoreType";
import {Paging} from "../type/CommonType";

interface State {
    list: Array<ExerciseType>,
    detail: ExerciseType,
    paging: Paging,
    statistics: ExerciseStatisticsType
}

const initialState: State = {
    list: [],
    detail: undefined,
    paging: undefined,
    statistics: {}
}

const workoutsSlice = createSlice({
    name: 'exercises',
    initialState: initialState,
    reducers: {
        setExercises(state, action) {
            state.list = action.payload;
        },
        setExercise(state, action) {
            state.detail = action.payload;
        },
        setPaging(state, action) {
            state.paging = action.payload;
        },
        setStatistics(state, action) {
            state.statistics = action.payload;
        }
    }
});

export const { setExercise, setExercises, setPaging, setStatistics } = workoutsSlice.actions;
export default workoutsSlice.reducer;