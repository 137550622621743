import { configureStore } from '@reduxjs/toolkit'

import WalletReducer from "./reducers/WalletReducer";
import UserReducer from "./reducers/UserReducer";
import WorkoutReducer from "./reducers/WorkoutReducer";
import ExerciseReducer from "./reducers/ExerciseReducer";
import CommonReducer from "./reducers/CommonReducer";
import CategoryReducer from "./reducers/CategoryReducer";
import SessionReducer from "./reducers/poker/SessionReducer";

export const store = configureStore({
    reducer: {
        commons: CommonReducer,
        users: UserReducer,
        workouts: WorkoutReducer,
        exercises: ExerciseReducer,
        wallets: WalletReducer,
        categories: CategoryReducer,
        sessions: SessionReducer
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch