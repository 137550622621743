import { createSlice } from '@reduxjs/toolkit';
import {SessionType, TaskType} from "../../type/poker/PokerStoreType";

interface State {
    list: SessionType[],
    detail: SessionType,
    tasks: TaskType[]
}

const initialState: State = {
    list: [],
    detail: undefined,
    tasks: []
}

const sessionsSlice = createSlice({
    name: 'session',
    initialState: initialState,
    reducers: {
        setSessions(state, action) {
            state.list = action.payload;
            state.detail = undefined;
            state.tasks = [];
        },
        setSession(state, action) {
            state.detail = action.payload;;
            state.list = [];
        },
        setTasks(state, action) {
            state.tasks = action.payload
        }
    }
});

export const { setSession, setSessions, setTasks } = sessionsSlice.actions;
export default sessionsSlice.reducer;