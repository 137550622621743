import { createSlice } from '@reduxjs/toolkit';
import {CategoryType} from "../type/StoreType";

interface State {
    list: CategoryType[],
    detail: CategoryType
}

const initialState: State = {
    list: [],
    detail: undefined
}

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: initialState,
    reducers: {
        setCategories(state, action) {
            state.list = action.payload;
            state.detail = undefined;
        },
        setCategory(state, action) {
            state.detail = action.payload;;
            state.list = [];
        }
    }
});

export const { setCategories, setCategory } = categoriesSlice.actions;
export default categoriesSlice.reducer;