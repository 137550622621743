import { createSlice } from '@reduxjs/toolkit';
import {ExerciseType, WorkoutType} from "../type/StoreType";
import {Paging} from "../type/CommonType";

interface State {
    modalError: string;
}

const initialState: State = {
    modalError: undefined
}

const commonsSlice = createSlice({
    name: 'commons',
    initialState: initialState,
    reducers: {
        setError(state, action) {
            state.modalError = action.payload;
        }
    }
});

export const { setError } = commonsSlice.actions;
export default commonsSlice.reducer;