import { createSlice } from '@reduxjs/toolkit';
import {WorkoutType} from "../type/StoreType";
import {Paging} from "../type/CommonType";

interface State {
    list: Array<WorkoutType>,
    detail: WorkoutType,
    paging: Paging
}

const initialState: State = {
    list: [],
    detail: undefined,
    paging: undefined
}

const workoutsSlice = createSlice({
    name: 'workouts',
    initialState: initialState,
    reducers: {
        setWorkouts(state, action) {
            const payload = action.payload;
            state.list = payload;
            state.detail = undefined;
        },
        setWorkout(state, action) {
            const payload = action.payload;
            state.detail = payload;
            state.list = [];
            state.paging = undefined;
        },
        setPaging(state, action) {
            state.paging = action.payload;
        }
    }
});

export const { setWorkout, setWorkouts, setPaging } = workoutsSlice.actions;
export default workoutsSlice.reducer;