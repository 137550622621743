import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
    name: 'users',
    initialState: {login: false, username: "", allowedApps: []},
    reducers: {
        setCurrentUser(state, action) {
            const payload = action.payload;
            state.login = payload.member;
            state.username = payload.username;
            state.allowedApps = payload.allowedApps;
        }
    }
});

export const { setCurrentUser } = usersSlice.actions;
export default usersSlice.reducer;