import { createSlice } from '@reduxjs/toolkit';
import {WalletType} from "../type/StoreType";

interface State {
    list: WalletType[],
    detail: WalletType
}

const initialState: State = {
    list: [],
    detail: undefined
}

const walletsSlice = createSlice({
    name: 'wallets',
    initialState: initialState,
    reducers: {
        setWallets(state, action) {
            state.list = action.payload;
            state.detail = undefined;
        },
        setWallet(state, action) {
            state.detail = action.payload;;
            state.list = [];
        }
    }
});

export const { setWallet, setWallets } = walletsSlice.actions;
export default walletsSlice.reducer;