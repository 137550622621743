import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Provider} from 'react-redux';
import {store} from './store';

import './style/index.scss';
import reportWebVitals from './reportWebVitals';
import Loading from './routes/Loading';

import 'bulma/css/bulma.min.css';

const Header = React.lazy(() => import("./component/Header"));
const Login = React.lazy(() => import("./routes/Login"));
const NotFound = React.lazy(() => import("./routes/NotFound"));


const Test = React.lazy(() => import("./routes/Test"));
const WalletList = React.lazy(() => import("./routes/expense-manager/WalletList"));
const WalletCreate = React.lazy(() => import("./routes/expense-manager/WalletCreate"));
const CategoryList = React.lazy(() => import("./routes/expense-manager/CategoryList"));
const CategoryCreate = React.lazy(() => import("./routes/expense-manager/CategoryCreate"));
const TransactionList = React.lazy(() => import("./routes/expense-manager/TransactionList"));
const TransactionDetail = React.lazy(() => import("./routes/expense-manager/TransactionDetail"));

const ExerciseDetail = React.lazy(() => import("./routes/fit-logs/ExerciseDetail"));
const ExerciseCreate = React.lazy(() => import("./routes/fit-logs/ExerciseCreate"));
const ExerciseUpdate = React.lazy(() => import("./routes/fit-logs/ExerciseUpdate"));
const ExercisesList = React.lazy(() => import("./routes/fit-logs/ExercisesList"));

const FitLogsHome = React.lazy(() => import("./routes/fit-logs/WorkoutsList"));
const WorkoutDetail = React.lazy(() => import("./routes/fit-logs/WorkoutDetail"));
const WorkoutCreate = React.lazy(() => import("./routes/fit-logs/WorkoutCreate"));
const WorkoutUpdate = React.lazy(() => import("./routes/fit-logs/WorkoutUpdate"));

const PokerHome = React.lazy(() => import("./routes/poker-planning/SessionsList"));
const SessionUpload = React.lazy(() => import("./routes/poker-planning/SessionUpload"));
const SessionDetail = React.lazy(() => import("./routes/poker-planning/SessionDetail"));

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderRoutes = () => {
    return (
        <React.Suspense fallback={<Loading/>}>
            <Routes>
                <Route path="/fit-logs" element={<FitLogsHome/>}/>
                <Route path="/fit-logs/exercises/create" element={<ExerciseCreate/>}/>
                <Route path="/fit-logs/exercises/:id" element={<ExerciseDetail/>}/>
                <Route path="/fit-logs/exercises/:id/update" element={<ExerciseUpdate/>}/>
                <Route path="/fit-logs/exercises" element={<ExercisesList/>}/>
                <Route path="/fit-logs/workouts/create" element={<WorkoutCreate/>}/>
                <Route path="/fit-logs/workouts/:id" element={<WorkoutDetail/>}/>
                <Route path="/fit-logs/workouts/:id/update" element={<WorkoutUpdate/>}/>

                <Route path="/login" element={<Login/>}/>


                <Route path="/exp-manager/categories/create" element={<CategoryCreate/>}/>
                <Route path="/exp-manager/wallets/:walletId/categories" element={<CategoryList/>}/>
                <Route path="/exp-manager/wallets/create" element={<WalletCreate/>}/>
                <Route path="/exp-manager/wallets" element={<WalletList/>}/>
                <Route path="/exp-manager" element={<TransactionList/>}/>

                <Route path="/poker/sessions/:sessionId/upload" element={<SessionUpload/>}/>
                <Route path="/poker/sessions/:sessionId" element={<SessionDetail/>}/>
                <Route path="/poker" element={<PokerHome/>}/>

                <Route path="*" element={<NotFound/>}/>


                // <Route path="/exp-manager/transactions/:id" element={<TransactionDetail isEdit={false}/>}/>
                // <Route path="/exp-manager/transactions/:id/update" element={<TransactionDetail isEdit={true}/>}/>
            </Routes>
        </React.Suspense>
    );
}

root.render(
    <Provider store={store}>
        <div className="columns">
            <div className="column is-6 is-offset-3 is-relative">
                <BrowserRouter>
                    <Header/>
                    {renderRoutes()}
                </BrowserRouter>
            </div>
        </div>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
